import React from "react"
import T from "prop-types"
import cx from "classnames"
import styles from "./Container.module.css"

export const Container = ({ children, tag, ...props }) => {
  const Tag = tag
  return (
    <Tag {...props} className={cx(styles.root, props.className)}>
      {children}
    </Tag>
  )
}

Container.propTypes = {
  children: T.node,
  tag: T.string.isRequired,
}

Container.defaultProps = {
  tag: "div",
}

export default Container
